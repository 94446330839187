// Generated by Framer (cec372b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Ww67VCvCG"];

const variantClassNames = {Ww67VCvCG: "framer-v-xqw1zl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; text?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "Ww67VCvCG", text: B7fn7eQey = "Our strategies in Digital Marketing aim at building brand awareness and visibility.", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Ww67VCvCG", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-jNdSK", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-xqw1zl", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Ww67VCvCG"} ref={ref} style={{...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FsdG9uZSBMaWdodA==", "--framer-font-family": "\"Altone Light\", serif", "--framer-font-size": "18px", "--framer-line-height": "28.85px", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Our approach increases the total customer base in the region.</motion.p></React.Fragment>} className={"framer-8uptb8"} data-framer-name={"Our approach increases the total customer base in the region."} fonts={["CUSTOM;Altone Light"]} layoutDependency={layoutDependency} layoutId={"JTFdMkxBH"} style={{"--extracted-r6o4lv": "var(--token-512a643a-6a48-47c6-8641-27985ce400fb, rgb(32, 23, 71)) ", "--framer-paragraph-spacing": "0px"}} text={B7fn7eQey} transition={transition} verticalAlignment={"center"} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-jNdSK [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jNdSK * { box-sizing: border-box; }", ".framer-jNdSK .framer-t2g8nx { display: block; }", ".framer-jNdSK .framer-xqw1zl { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 237px; }", ".framer-jNdSK .framer-8uptb8 { flex: none; height: auto; overflow: hidden; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jNdSK .framer-xqw1zl { gap: 0px; } .framer-jNdSK .framer-xqw1zl > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-jNdSK .framer-xqw1zl > :first-child { margin-top: 0px; } .framer-jNdSK .framer-xqw1zl > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 115.5
 * @framerIntrinsicWidth 237
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"B7fn7eQey":"text"}
 */
const FramerNo3LqbhOD: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerNo3LqbhOD;

FramerNo3LqbhOD.displayName = "Archive / Paragraph";

FramerNo3LqbhOD.defaultProps = {height: 115.5, width: 237};

addPropertyControls(FramerNo3LqbhOD, {B7fn7eQey: {defaultValue: "Our strategies in Digital Marketing aim at building brand awareness and visibility.", displayTextArea: true, title: "Text", type: ControlType.String}} as any)

addFonts(FramerNo3LqbhOD, [{family: "Altone Light", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/No3LqbhOD:default", url: "assets/o9vU2jZnRxM9i4gs3Mg5ocHJwV4.ttf"}, url: new URL("assets/o9vU2jZnRxM9i4gs3Mg5ocHJwV4.ttf", import.meta.url).href}])